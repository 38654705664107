.uploadAnimation {
    background-color: rgb(155 155 155 / 25%);
    background-image: linear-gradient(90deg, #5b5b5b 60%, transparent 40%), linear-gradient(90deg, #5b5b5b 60%, transparent 40%), linear-gradient(0deg, #5b5b5b 60%, transparent 40%), linear-gradient(0deg, #5b5b5b 60%, transparent 40%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 40px 4px, 40px 4px, 4px 40px, 4px 40px;
    background-position: left top, right bottom, left bottom, right   top;
    animation: border-dance 1s infinite linear;
  }
  @keyframes border-dance {
    0% {
      background-position: left top, right bottom, left bottom, right   top;
    }
    100% {
      background-position: left 40px top, right 40px bottom , left bottom 40px , right   top 40px;
    }
  }