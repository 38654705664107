.snack{ 
  height: 'auto';
  line-height: '28px';
  padding: 24; 
  white-space: 'pre-line' 
}


.errLabel {
  width: 50vw;
  margin-top: 10vh;
  font-weight: bold;
  font-size: 20px;
  font-style: italic;
  color: #f51a1a;
}